import style from './LandingFoqus.module.css'
import Header from "./sections/Header";
import Business from "./sections/Business";
import Advantages from "./sections/Advantages";
import TrustedBy from "./sections/TrustedBy";
import HowItWorks from "./sections/HowItWorks";
import Promo from "./sections/Promo";
import CaseStudy from "./sections/CaseStudy";
import RequestDemo from "./sections/RequestDemo";
import Footer from "./sections/Footer";
import dots_bg from "../../images/dot_bg_2.png";
import ResponsePopup from "./sections/ResponsePopup";
export default function LandingFoqus(){
	const isPhone = window.outerWidth < 1200
	return <div className={style.landingfoqus_wrapper}>
		<Header/>
		<Promo/>
		<Business/>
		<Advantages isPhone={isPhone}/>
		<TrustedBy/>
		<CaseStudy isPhone={isPhone}/>
		<HowItWorks/>
		<RequestDemo isPhone={isPhone}/>
		<Footer/>
		<ResponsePopup />
		{
			!isPhone && <img src={dots_bg} className={style.dots_bg}/>
		}
	</div>
}
