import style from "../LandingFoqus.module.css";
import logo from "../../../images/Foqus_white.png";
import header_icon from '../../../images/header_icon.svg'
import login_arrow from '../../../images/login_arrow.svg'
import {Link} from '@aws-amplify/ui-react';
import {useUnit} from "effector-react";
import {$showMenu, toggleShowMenuEv} from "../../../models/newLandingModel";

export default function Header() {

  const showMenu = useUnit($showMenu)
  const toggleShowMenu = useUnit(toggleShowMenuEv)

  return <>
    <div className={[style.header_outclick_overlay, showMenu ? style.showOverlay : style.hideOverlay].join(' ')} onClick={toggleShowMenu}/>
    <header className={[style.header, showMenu ? style.showMenu : style.hideMenu].join(' ')}>
      <div className={style.container} style={{height: '100%'}}>
        <div className={style.header_wrapper}>
          <img src={logo} className={style.header_foqus_logo}/>
          <ul className={style.header_menu_wrapper}>
            <li className={style.header_menu_item}>About us</li>
            <li className={style.header_menu_item}>Contacts</li>
            <li className={style.header_menu_item}>Blog</li>
          </ul>
          <div className={style.header_login_wrapper}>
            <Link target={'_blank'} href={'https://team-iw18119.slack.com/team/U050SG2ET55'}>
              <img src={header_icon} className={style.header_icon}/>
            </Link>
            <Link target={'_blank'} href={'https://foqus-portal.live'}>
              <div className={style.login_button}>
                Log in
                <img src={login_arrow} style={{marginLeft: '10px'}} className={style.login_arrow}/>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </header>
  </>
}
