import {sample} from "effector";
import {sendRequestDemoFx} from "./effects";
import {resetShowPopupEv, sendRequestDemoEv, toggleShowMenuEv} from "./events";
import {$showMenu, $showPopup} from "./stores";
import dayjs from "dayjs";

$showPopup.on(sendRequestDemoFx.doneData, (state, _) => true)
	.reset(resetShowPopupEv)

$showMenu.on(toggleShowMenuEv, (state, _) => !state)

sample({
	clock: sendRequestDemoEv,
	fn: (clock) => {
		return {
			...clock,
			seventh_value: dayjs().format('YYYY-MM-DD')
		}
	},
	target: sendRequestDemoFx
})

