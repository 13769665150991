import {createEvent} from "effector";

export const toggleDarkThemeEv = createEvent()

export const changeUserDataEv = createEvent()

export const sendRegistrationDataEv = createEvent()

export const setNextStepEv = createEvent()

export const formIsValidEv = createEvent()

export const setFormClickedButtonEv = createEvent()

export const saveFormDataEv = createEvent()

export const finishHREventEv = createEvent()

export const saveToS3Ev = createEvent()
