import style from '../LandingFoqus.module.css'
import alageum_logo from '../../../images/trustedBy/alageum_logo.png'
import amanat_logo from '../../../images/trustedBy/amanat_logo.png'
import kolibrix_logo from '../../../images/trustedBy/kolibrix_logo.png'
import nomad_logo from '../../../images/trustedBy/nomad_logo.png'
import s89_logo from '../../../images/trustedBy/s89_logo.png'
import zyp_logo from '../../../images/trustedBy/zyp_logo.png'
import aubakirova_logo from '../../../images/trustedBy/aubakirova_logo.png'
import creatti_logo from '../../../images/trustedBy/creatti_logo.png'
import bla_logo from '../../../images/trustedBy/bla_logo.png'
import myata_logo from '../../../images/trustedBy/myata_logo.png'
import Slider from "react-slick";
import splat_5 from "../../../images/newLangingSplats/splat_5.png";


export default function TrustedBy(){
	const settings = {
		dots: false,
		infinite: true,
		speed: 2000,
		slidesToShow: 6,
		slidesToScroll: 1,
		autoplaySpeed: 2000,
		autoplay: true,
		arrows: false,
		pauseOnHover: false,
		cssEase: "linear",
	};

	return <div className={style.trustedBy_wrapper}>
		<div className={style.container}>
			<div className={style.trustedBy_title}>
				Trusted by industry leaders
			</div>
		</div>
		<div style={{position: 'relative', zIndex: '2'}} className={`'slider-container`}>
			<Slider {...settings}>
				<div className={style.trustedBy_item}>
					<img src={alageum_logo} className={style.trustedBy_img}/>
				</div>
				<div className={style.trustedBy_separator}/>
				<div className={style.trustedBy_item}>
					<img src={amanat_logo} className={style.trustedBy_img}/>
				</div>
				<div className={style.trustedBy_separator}/>
				<div className={style.trustedBy_item}>
					<img src={zyp_logo} className={style.trustedBy_img}/>
				</div>
				<div className={style.trustedBy_separator}/>
				<div className={style.trustedBy_item}>
					<img src={kolibrix_logo} className={style.trustedBy_img}/>
				</div>
				<div className={style.trustedBy_separator}/>
				<div className={style.trustedBy_item}>
					<img src={s89_logo} className={style.trustedBy_img}/>
				</div>
				<div className={style.trustedBy_separator}/>
				<div className={style.trustedBy_item}>
					<img src={nomad_logo} className={style.trustedBy_img}/>
				</div>
				<div className={style.trustedBy_separator}/>
				<div className={style.trustedBy_item}>
					<img src={aubakirova_logo} className={style.trustedBy_img}/>
				</div>
				<div className={style.trustedBy_separator}/>
				<div className={style.trustedBy_item}>
					<img src={creatti_logo} className={style.trustedBy_img}/>
				</div>
				<div className={style.trustedBy_separator}/>
				<div className={style.trustedBy_item}>
					<img src={bla_logo} className={style.trustedBy_img}/>
				</div>
				<div className={style.trustedBy_separator}/>
				<div className={style.trustedBy_item}>
					<img src={myata_logo} className={style.trustedBy_img}/>
				</div>
				<div className={style.trustedBy_separator}/>
			</Slider>
		</div>
		<img src={splat_5} id={'splat_5'}/>
	</div>
}
