import {$showSuccess} from "./stores";
import {toggleShowSuccessEv} from "./events";
import {sample} from "effector";
import {sendLandingRegistrationFx} from "./effects";

$showSuccess.on(toggleShowSuccessEv, (state, _) => !state)

sample({
	clock: sendLandingRegistrationFx.doneData,
	target: toggleShowSuccessEv
})
