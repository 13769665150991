import style from "../LandingFoqus.module.css";
import advantages_arrow from '../../../images/advantages/advantages_arrow.svg'
import advantages_1 from '../../../images/advantages/advantages_1.png'
import advantages_2 from '../../../images/advantages/advantages_2.png'
import advantages_3 from '../../../images/advantages/advantages_3.png'
import advantages_4 from '../../../images/advantages/advantages_4.svg'
import advantages_5 from '../../../images/advantages/advantages_5.svg'
import advantages_6 from '../../../images/advantages/advantages_6.svg'
import advantages_7 from '../../../images/advantages/advantages_7.svg'
import advantages_8 from '../../../images/advantages/advantages_8.svg'
import advantages_9 from '../../../images/advantages/advantages_9.svg'
import advantages_line from '../../../images/advantages/advantages_line.png'
import splat_3 from "../../../images/newLangingSplats/splat_3.png";
import splat_4 from "../../../images/newLangingSplats/splat_4.png";

export default function Advantages({isPhone}) {
	return <div className={style.advantages_wrapper}>
		<div className={style.container}>
			<div className={style.advantages_title}>
				Advantages
				<img src={advantages_arrow} className={style.advantages_arrow}/>
			</div>
			<div className={style.advantages_cards_wrapper}>
				<div className={style.advantages_card}>
					<div className={style.advantages_card_title}>
						Human <div className={style.advantages_gradient}>→</div> first approach
					</div>
					<div className={style.advantages_subcards_wrapper}>
						<div className={style.advantages_subcard}>
							<div className={style.advantages_subcard_title}>
								Happiness Index
							</div>
							<img
								src={advantages_1}
								className={style.advantages_img}
								style={{
									margin: isPhone ? '52px 0px 36px 0px' : 'unset',
								}}
							/>
							<div className={style.advantages_subcard_desc}>
								Our algorithms recognize eight emotions, allowing us to aggregate positive emotions into a single
								indicator
							</div>
						</div>
						<div className={style.advantages_subcard}>
							<div className={style.advantages_subcard_title}>
								Social Dynamics
							</div>
							<img
								src={advantages_2}
								className={style.advantages_img}
								style={{
									margin: isPhone ? '32px 0px 40px 0px' : 'unset',
								}}
							/>
							<div className={style.advantages_subcard_desc}>
								We model complex relationships and dependencies with Graph Network technology to provide Instagram links
							</div>
						</div>
						<div className={style.advantages_subcard}>
							<div className={style.advantages_subcard_title}>
								Personality model
							</div>
							<img
								src={advantages_3}
								className={style.advantages_img}
								style={{
									margin: isPhone ? '28px 0px 27px 0px' : 'unset',
								}}
							/>
							<div className={style.advantages_subcard_desc}>
								We provide a 5 factor personality tests to analyze the correlations between the behavior and personal
								traits
							</div>
						</div>
					</div>
				</div>
				<div className={style.advantages_card}>
					<div className={style.advantages_card_title}>
						Custom solutions for every <div className={style.advantages_gradient}>→</div> business need
					</div>
					<div className={style.advantages_subcards_wrapper} style={{marginTop: '110px'}}>
						<div className={[style.advantages_subcard, style.advantages_subcard_2].join(' ')}>
							<div style={{height: '265px', width: '350px'}}>
								<img src={advantages_4}/>
								<div className={[style.advantages_subcard_title, style.advantages_subcard_title_2].join(' ')}>
									HR Module:
								</div>
								<div className={style.advantages_subcard_desc}>
									Streamline hiring, monitor employee engagement, and enhance workplace efficiency
								</div>
							</div>
							<ul className={style.advantages_subcard_dots}>
								<li className={style.advantages_subcard_dot}>Employee emotion analysis</li>
								<li className={style.advantages_subcard_dot}>In/out timesheet management</li>
								<li className={style.advantages_subcard_dot}>Engagement tracking</li>
							</ul>
						</div>
						<div className={[style.advantages_subcard, style.advantages_subcard_2].join(' ')}>
							<div style={{height: '265px', width: '350px'}}>
								<img src={advantages_5}/>
								<div className={[style.advantages_subcard_title, style.advantages_subcard_title_2].join(' ')}>
									Marketing Module:
								</div>
								<div className={style.advantages_subcard_desc}>
									Understand customer behavior, drive higher engagement and provide personalized service
								</div>
							</div>
							<ul className={style.advantages_subcard_dots}>
								<li className={style.advantages_subcard_dot}>Retention of unique visitors</li>
								<li className={style.advantages_subcard_dot}>Age range analysis</li>
								<li className={style.advantages_subcard_dot}>Gender distribution</li>
							</ul>
						</div>
						<div className={[style.advantages_subcard, style.advantages_subcard_2].join(' ')}>
							<div style={{height: '265px', width: '350px'}}>
								<img src={advantages_6}/>
								<div className={[style.advantages_subcard_title, style.advantages_subcard_title_2].join(' ')}>
									Security Module:
								</div>
								<div className={style.advantages_subcard_desc}>
									Enhance safety, monitor environments,
									and respond to threats in real-time through instant alerts
								</div>
							</div>
							<ul className={style.advantages_subcard_dots}>
								<li className={style.advantages_subcard_dot}>Facial recognition for access control</li>
								<li className={style.advantages_subcard_dot}>Real-time monitoring of restricted areas</li>
								<li className={style.advantages_subcard_dot}>Incident detection and alerting</li>
							</ul>
						</div>
					</div>
				</div>
				<div className={style.advantages_card} style={{paddingRight: '80px'}}>
					<div className={style.advantages_card_title}>
						Industry ready solutions
						<div className={style.advantages_gradient}>→ Smart School</div>
					</div>
					<div className={style.advantages_subcards_wrapper} style={{marginTop: '110px'}}>
						<div className={[style.advantages_subcard, style.advantages_subcard_2].join(' ')} >
							<img src={advantages_7}/>
							<div className={[style.advantages_subcard_title, style.advantages_subcard_title_2].join(' ')}>
								Education
							</div>
							<div className={style.advantages_subcard_desc}>
								Using a video camera in the classroom, our service monitors behavior to enhance teacher and student
								engagement during lessons and track irrelevant activities.
							</div>
						</div>
						<div className={[style.advantages_subcard, style.advantages_subcard_2].join(' ')} >
							<img src={advantages_8}/>
							<div className={[style.advantages_subcard_title, style.advantages_subcard_title_2].join(' ')}>
								Security
							</div>
							<div className={style.advantages_subcard_desc}>
								Facial recognition ensures only authorized people have access, with alerts sent to messengers.
							</div>
						</div>
						<div className={[style.advantages_subcard, style.advantages_subcard_2].join(' ')} >
							<img src={advantages_9}/>
							<div className={[style.advantages_subcard_title, style.advantages_subcard_title_2].join(' ')}>
								Administration
							</div>
							<div className={style.advantages_subcard_desc}>
								For effective school management we provide data in BI analytics
							</div>
						</div>
					</div>
					<img src={advantages_line} className={style.advantages_line}/>
				</div>
			</div>
		</div>
		<img src={splat_3} id={'splat_3'}/>
		<img src={splat_4} id={'splat_4'}/>
	</div>
}
