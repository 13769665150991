import style from "../LandingFoqus.module.css";
import new_landing_laptop from '../../../images/new_landing_laptop.png'
import aws_logo from '../../../images/aws_logo.png'
import vector_right from '../../../images/vector_right.png'
import vector_left from '../../../images/vector_left.png'

export default function Business(){
	return <div className={style.business_wrapper}>
		<div className={style.container}>
			<div className={style.business_title}>
				Doing business is <div className={style.business_hard}>HARD</div>
			</div>
			<div className={style.business_subtitle}>
				Actually, it's Hard without us
			</div>
			<div style={{position: 'relative'}}>
				<div className={style.business_info_block} id={'info_block_1'}>
					Management without helicopter view is like navigating in the dark.
				</div>
				<div className={style.business_info_block} id={'info_block_2'}>
					Aggregated insights in a single interface simplify everything — making your operations seamless and your
					decisions smarter.
				</div>
				<div className={style.business_info_block} id={'info_block_3'}>
					With our partnership with AWS, we turn complexity into clarity, giving you the control and confidence to
					thrive.
				</div>
				<div style={{position: 'relative'}}>
					<img src={new_landing_laptop} className={style.business_laptop}/>
					<img src={vector_right} className={style.vector_right}/>
					<img src={vector_left} className={style.vector_left}/>
				</div>
			</div>
			<img src={aws_logo} className={style.aws_logo}/>
		</div>
	</div>
}
