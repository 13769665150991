import style from "../LandingFoqus.module.css";
import foqus_logo_framed from "../../../images/foqus_logo_framed.png";
import footer_logo_splat from '../../../images/newLangingSplats/footer_logo_splat.png'
import logo from "../../../images/Foqus_white.png";
import linkedin from "../../../images/linkedin.svg";


export default function Footer(){
	return <div className={style.footer_wrapper}>
		<div className={style.container}>
			<div className={style.footer_items_warpper}>
				<img src={logo} className={style.footer_mobile_logo}/>
				<div>
					<div className={style.footer_title}>Contacts</div>
					<div className={style.footer_text}>Almaty, 22/5, st. Satpayev, office 202<br/>
						+7 771 863 77 77
					</div>
					<img src={linkedin} className={style.footer_linkedin}/>
					<div className={style.footer_subtext}>
						2024, copyright FOQUS
					</div>
				</div>
				<div className={style.footer_logo_wrapper}>
					<img src={foqus_logo_framed} className={style.footer_logo}/>
					<img src={footer_logo_splat} className={style.footer_logo_splat}/>
				</div>
			</div>
		</div>
	</div>
}
