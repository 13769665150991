import style from "../LandingFoqus.module.css";
import arristol_new from '../../../images/arristol_new.png'
import splat_1 from '../../../images/newLangingSplats/splat_1.png'
import splat_2 from '../../../images/newLangingSplats/splat_2.png'
import logo from "../../../images/Foqus_white.png";
import {MenuOutlined} from '@ant-design/icons';
import {useUnit} from "effector-react";
import {toggleShowMenuEv} from "../../../models/newLandingModel";

export default function Promo(){
	const toggleShowMenu = useUnit(toggleShowMenuEv)

	return <div className={style.promo_wrapper}>
		<div className={style.container} style={{position: 'relative'}}>
			<div className={style.promo_header_wrapper}>
				<img src={logo} className={style.promo_logo}/>
				<MenuOutlined className={style.promo_hamburger} onClick={toggleShowMenu}/>
			</div>
			<div className={style.promo_title}>
				AI Vision:
			</div>
			<div className={style.promo_subtitle}>
				Behavioral Intelligence<br/>
				for Business Growth
			</div>
			<a className={style.promo_button} href={'#requestDemo'}>
				Request a DEMO
			</a>
			<img className={style.promo_img} src={arristol_new}/>
		</div>
		<img src={splat_1} id={'splat_1'}/>
		<img src={splat_2} id={'splat_2'}/>
	</div>
}
