import style from '../LandingFoqus.module.css'
import slack from '../../../images/requestDemo/slack.svg'
import telegram from '../../../images/requestDemo/telegram.svg'
import whatsapp from '../../../images/requestDemo/whatsapp.svg'
import {Button, Form, Input} from "antd";
import {sendRequestDemoEv, sendRequestDemoFx} from "../../../models/newLandingModel";
import {useUnit} from "effector-react/compat";

export default function RequestDemo({isPhone}){

	const sendRequestDemo = useUnit(sendRequestDemoEv)
	const loading = useUnit(sendRequestDemoFx.pending)

	return <div className={style.requestDemo_wrapper} id={'requestDemo'}>
		<div className={style.container} style={{margin: isPhone ? 'unset' : '0 auto', width: isPhone ? '100%' : '90rem'}}>
			<div className={style.requestDemo_card}>
				<div className={style.requestDemo_info}>
					<div>
						<div className={style.requestDemo_title}>
							Request a <div className={style.gradient_text}>Demo</div>
						</div>
						<div className={style.requestDemo_subtitle}>
							or contact us directly
						</div>
						<div className={style.requestDemo_links_wrapper}>
							<a href={'https://team-iw18119.slack.com/team/U050SG2ET55'} target={'_blank'}>
								<img src={slack}/>
							</a>
							<a href={'https://t.me/SerikJardem'} target={'_blank'}>
								<img src={telegram}/>
							</a>
							<a href={'https://wa.me/qr/WCHX3YAZ7YMBO1'} target={'_blank'}>
								<img src={whatsapp}/>
							</a>
						</div>
					</div>
					<div className={style.requestDemo_subdesc}>
						By clicking on "Request a demo"<br/> you agree to the right to process<br/> your data
					</div>
				</div>
				<div className={style.requestDemo_form}>
					<Form
						layout="vertical"
						onFinish={sendRequestDemo}
					>
						<Form.Item
							name={'first_value'}
							label={<label className={style.requestDemo_form_label}>Name</label>}
						>
							<Input className={style.requestDemo_form_input} placeholder={'Enter your name'}/>
						</Form.Item>
						<Form.Item
							name={'second_value'}
							label={<label className={style.requestDemo_form_label}>Phone</label>}
						>
							<Input className={style.requestDemo_form_input} placeholder={'Enter your phone number'}/>
						</Form.Item>
						<Form.Item
							name={'third_value'}
							label={<label className={style.requestDemo_form_label}>Email</label>}
						>
							<Input className={style.requestDemo_form_input} placeholder={'Enter your email'}/>
						</Form.Item>
						<Form.Item
							name={'fourth_value'}
							label={<label className={style.requestDemo_form_label}>Industry</label>}
						>
							<Input className={style.requestDemo_form_input} placeholder={'Enter your industry or company'}/>
						</Form.Item>
						<Form.Item
							name={'fifth_value'}
							label={<label className={style.requestDemo_form_label}>City</label>}
						>
							<Input className={style.requestDemo_form_input} placeholder={'Enter your city'}/>
						</Form.Item>
						<Form.Item
							name={'sixth_value'}
							label={<label className={style.requestDemo_form_label}>Country</label>}
						>
							<Input className={style.requestDemo_form_input} placeholder={'Enter your country'}/>
						</Form.Item>
						<Form.Item
						>
							<Button type="primary" htmlType="submit" className={style.requestDemo_form_button} loading={loading}>
								Request a demo
							</Button>
						</Form.Item>
					</Form>
				</div>
			</div>
		</div>
	</div>
}
