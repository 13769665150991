import style from '../LandingFoqus.module.css'
import education from '../../../images/caseStudy/education.png'
import gas_station from '../../../images/caseStudy/gas_station.png'
import health_care from '../../../images/caseStudy/health_care.png'
import horeca from '../../../images/caseStudy/horeca.png'
import insuranse from '../../../images/caseStudy/insuranse.png'
import manufacturing from '../../../images/caseStudy/manufacturing.png'
import retail from '../../../images/caseStudy/retail.png'
import advantages_arrow from "../../../images/advantages/advantages_arrow.svg";
import Slider from "react-slick";
import {useMemo} from "react";

export default function CaseStudy({isPhone}){
	const settings = {
		dots: false,
		infinite: true,
		speed: 5000,
		slidesToShow: isPhone ? 1 : 2,
		slidesToScroll: 1,
		autoplaySpeed: 5000,
		autoplay: true,
		pauseOnHover: true,
		arrows: false,
		cssEase: "linear"
	};

	const cardsWidth = useMemo(() => {
		return  (window.innerWidth / (isPhone ? 1 : 2)) - 40
	}, [window.innerWidth])

	return <div className={style.caseStudy_wrapper}>
		<div className={style.container}>
			<div className={style.caseStudy_title}>
				Case study
				<img src={advantages_arrow} className={style.advantages_arrow}/>
			</div>
		</div>
		<div className={'slider-contaier'} style={{marginTop: isPhone ? '30px' : '70px'}}>
			<Slider {...settings}>
				<div className={style.caseStudy_card}>
					<img src={education} className={style.caseStudy_img} style={{width: `${cardsWidth}px`}}/>
					<div className={style.caseStudy_card_overlay}/>
					<div className={style.caseStudy_text_block}>
						<div className={style.caseStudy_card_title}>
							Issue
						</div>
						<div className={style.caseStudy_card_desc}>
							Difficulty in monitoring and assessing student engagement
						</div>
						<div className={style.caseStudy_card_title} style={{marginTop: '41px'}}>
							Solution
						</div>
						<div className={style.caseStudy_card_desc}>
							AI Vision for Classroom Monitoring and Time management
						</div>
					</div>
				</div>
				<div className={style.caseStudy_card}>
					<img src={insuranse} className={style.caseStudy_img} style={{width: `${cardsWidth}px`}}/>
					<div className={style.caseStudy_card_overlay}/>
					<div className={style.caseStudy_text_block}>
						<div className={style.caseStudy_card_title}>
							Issue
						</div>
						<div className={style.caseStudy_card_desc}>
							Inefficient time management affecting productivity
						</div>
						<div className={style.caseStudy_card_title} style={{marginTop: '41px'}}>
							Solution
						</div>
						<div className={style.caseStudy_card_desc}>
							Real-time facial recognition and attendance tracking
						</div>
					</div>
				</div>
				<div className={style.caseStudy_card}>
					<img src={gas_station} className={style.caseStudy_img} style={{width: `${cardsWidth}px`}}/>
					<div className={style.caseStudy_card_overlay}/>
					<div className={style.caseStudy_text_block}>
						<div className={style.caseStudy_card_title}>
							Issue
						</div>
						<div className={style.caseStudy_card_desc}>
							Fraud threats and inefficient cash flow management
						</div>
						<div className={style.caseStudy_card_title} style={{marginTop: '41px'}}>
							Solution
						</div>
						<div className={style.caseStudy_card_desc}>
							Operations analytics and POS monitoring for security
						</div>
					</div>
				</div>
				<div className={style.caseStudy_card}>
					<img src={retail} className={style.caseStudy_img} style={{width: `${cardsWidth}px`}}/>
					<div className={style.caseStudy_card_overlay}/>
					<div className={style.caseStudy_text_block}>
						<div className={style.caseStudy_card_title}>
							Issue
						</div>
						<div className={style.caseStudy_card_desc}>
							Low level of cashier engagement and knowledge of customer profile
						</div>
						<div className={style.caseStudy_card_title} style={{marginTop: '41px'}}>
							Solution
						</div>
						<div className={style.caseStudy_card_desc}>
							AI Vision for Customer Behavior Analysis and Demographics data
						</div>
					</div>
				</div>
				<div className={style.caseStudy_card}>
					<img src={horeca} className={style.caseStudy_img} style={{width: `${cardsWidth}px`}}/>
					<div className={style.caseStudy_card_overlay}/>
					<div className={style.caseStudy_text_block}>
						<div className={style.caseStudy_card_title}>
							Issue
						</div>
						<div className={style.caseStudy_card_desc}>
							Lack of knowledge on guest desires, interests and emotion status
						</div>
						<div className={style.caseStudy_card_title} style={{marginTop: '41px'}}>
							Solution
						</div>
						<div className={style.caseStudy_card_desc}>
							Loyalty system through QR and instant alert to Telegram on guests
						</div>
					</div>
				</div>
				<div className={style.caseStudy_card}>
					<img src={health_care} className={style.caseStudy_img} style={{width: `${cardsWidth}px`}}/>
					<div className={style.caseStudy_card_overlay}/>
					<div className={style.caseStudy_text_block}>
						<div className={style.caseStudy_card_title}>
							Issue
						</div>
						<div className={style.caseStudy_card_desc}>
							Conversion from registry to scanning cabinet
						</div>
						<div className={style.caseStudy_card_title} style={{marginTop: '41px'}}>
							Solution
						</div>
						<div className={style.caseStudy_card_desc}>
							AI Vision for Patient Flow Management
						</div>
					</div>
				</div>
				<div className={style.caseStudy_card}>
					<img src={manufacturing} className={style.caseStudy_img} style={{width: `${cardsWidth}px`}}/>
					<div className={style.caseStudy_card_overlay}/>
					<div className={style.caseStudy_text_block}>
						<div className={style.caseStudy_card_title}>
							Issue
						</div>
						<div className={style.caseStudy_card_desc}>
							High operational costs due to inefficiencies
						</div>
						<div className={style.caseStudy_card_title} style={{marginTop: '41px'}}>
							Solution
						</div>
						<div className={style.caseStudy_card_desc}>
							Real-time monitoring of production lines and employee performance
						</div>
					</div>
				</div>
			</Slider>
		</div>
	</div>
}
