import style from './Registration.module.css'
import InfoBlock from "./InfoBlock";
import {Checkbox, Form, Input} from "antd";
import {$userData} from "../../models/registrationModel";
import {useUnit} from "effector-react";
import {Link} from "@aws-amplify/ui-react";
import {useEffect} from "react";
import {saveFormDataEv, setNextStepEv} from "../../models/onboardingModel";

export default function FormPage(props){

	const { form } = props
	const userData = useUnit($userData)
	const setNextStep = useUnit(setNextStepEv)
	const saveFormData = useUnit(saveFormDataEv)

	const onFinish = (values) => {
		saveFormData(values)
		setNextStep()
	}

	useEffect(() => {
		form.setFieldValue('birthday', userData.birthday)
	}, [userData.birthday]);

	return <div className={style.form_page_wrapper}>
		<InfoBlock
			title={'Рады приветствовать вас!'}
			description={'Для участия в интерактиве и получения подарка, пожалуйста, заполните свои данные'}
		/>
		<Form form={form} className={style.form_wrapper} onFinish={onFinish}>
			<Form.Item
				name={'name'}
				style={{marginBottom: '12px'}}
				rules={[
					{
						required: true,
						message: 'Пожалуйста введите имя!',
					},
				]}
			>
				<Input className={style.form_input} placeholder={'Имя'}/>
			</Form.Item>
			<Form.Item
				name={'surname'}
				style={{marginBottom: '12px'}}
				rules={[
					{
						required: true,
						message: 'Пожалуйста введите фамилию!',
					},
				]}
			>
				<Input className={style.form_input} placeholder={'Фамилия'}/>
			</Form.Item>
			<Form.Item
				name={'work'}
				style={{marginBottom: '12px'}}
				rules={[
					{
						required: true,
						message: 'Пожалуйста введите место работы!',
					},
				]}
			>
				<Input className={style.form_input} placeholder={'Место работы'}/>
			</Form.Item>
			<Form.Item
				name={'isAgreement'}
				valuePropName="checked"
				style={{
					textAlign: 'left'
				}}
				rules={[
					{
						required: true,
						message: 'Пожалуйста подтвердите согласие!',
					},
					{
						validator: async () => {
							if (userData.isAgreement == false) {
								return Promise.reject(
									new Error("Пожалуйста подтвердите согласие!"),
								);
							}
						},
					},
				]}
			>
				<Checkbox className={style.form_checkbox}>
					Я согласен на обработку <Link href={'/terms'} style={{color: '#005FF9'}}>данных</Link>
				</Checkbox>
			</Form.Item>
		</Form>
	</div>
}
