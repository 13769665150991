import {useEffect, useRef, useState} from "react";
import style from './Registration.module.css'
import {Button} from "antd";
import {
	$currentPage,
	$userData,
	changeUserDataEv, saveToS3Ev,
	sendRegistrationDataEv,
	setNextStepEv
} from "../../models/onboardingModel";
import {useUnit} from "effector-react";


export default function FaceScanPage() {
	const [animation, setAnimation] = useState(false)
	const videoRef = useRef()
	const recordedRef = useRef()
	const canvasRef = useRef()
	const sendRegistration = useUnit(sendRegistrationDataEv)
	const changeUserData = useUnit(changeUserDataEv)
	const {images} = useUnit($userData)
	const setNextStep = useUnit(setNextStepEv)
	const currentPage = useUnit($currentPage)
	const [mediaRecorder, setMediaRecorder] = useState(null)
	const [recordedBlobs, setRecordedBlobs] = useState([])
	const [isRecording, setIsRecording] = useState(false)
	const saveToS3 = useUnit(saveToS3Ev)
	const userData = useUnit($userData)

	const handleRecordData = (event) => {
		if(event.data && event.data.size > 0) setRecordedBlobs([...recordedBlobs, event.data])
	}

	useEffect(() => {
		if(recordedBlobs.length > 0){

			//FIXME Recorded video is here
			// Buffer
			const superBuffer = new Blob(recordedBlobs, {type: 'video/mp4'});
			// console.log('superBuffer', superBuffer)

			//FIXME File
			const file = new File([superBuffer], `${userData.name}_${userData.surname}_onboarding_rec_${Date.now()}`
				, {lastModified: new Date().getTime(), type: superBuffer.type})

			saveToS3(file)

			// console.log('file', file)
			// recordedRef.current.src = null
			// recordedRef.current.srcObject = null
			// recordedRef.current.src = window.URL.createObjectURL(superBuffer)

			//FIXME blob
			// console.log(window.URL.createObjectURL(superBuffer))

			let reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = function () {
				//FIXME base64
				// console.log(reader.result);
			};
			reader.onerror = function (error) {
				console.log('Error: ', error);
			};

			// recordedRef.current.controls = true
			// recordedRef.current.play()

		}
	}, [recordedBlobs]);

	const startVideo = () => {
		navigator.mediaDevices.getUserMedia({video: true})
			.then((currentStream) => {
				videoRef.current.srcObject = currentStream
				setMediaRecorder(new MediaRecorder(currentStream, {
					mimeType: 'video/mp4;codecs=vp9,opus'
				}))
			})
			.catch((err) => {
				console.log(err)
			})
	}

	const startRecording = () => {
		setIsRecording(true)
		mediaRecorder.ondataavailable = handleRecordData
		mediaRecorder.start();
	}

	const stopRecording = () => {
		setIsRecording(false)
		mediaRecorder.stop()
	}

	useEffect(() => {
		startVideo()
		videoRef.current.setAttribute('autoplay', '');
		videoRef.current.setAttribute('muted', '');
		videoRef.current.setAttribute('playsinline', '')

		videoRef.current.addEventListener('canplay', () => {

			const width = window.innerWidth
			const height = window.innerHeight - 136

			canvasRef.current.width = window.innerWidth
			canvasRef.current.height = window.innerHeight

			return setInterval(() => {
				if(canvasRef.current){
					canvasRef.current.getContext('2d').drawImage(videoRef.current, 0, 0, width, height)
				}
			})
		})
	}, []);

	return <>
		{/*<div className={style.info_block} style={{*/}
		{/*	position: 'absolute',*/}
		{/*	zIndex: 2,*/}
		{/*	width: '100%',*/}
		{/*}}>*/}
		{/*	<div className={style.info_title}>Выполните действия</div>*/}
		{/*	<div className={style.info_point} style={{marginBottom: '25px'}}>{steps_titles1[images.length]}</div>*/}
		{/*	<CustomSlider*/}
		{/*		points={[*/}
		{/*			{*/}
		{/*				id: 0*/}
		{/*			},*/}
		{/*			{*/}
		{/*				id: 1*/}
		{/*			},*/}
		{/*			{*/}
		{/*				id: 2*/}
		{/*			},*/}
		{/*			{*/}
		{/*				id: 3*/}
		{/*			},*/}
		{/*		]}*/}
		{/*		activePoint={images.length > 0 ? images.length - 1 : undefined}*/}
		{/*	/>*/}
		{/*</div>*/}
		<div className={style.facescan_wrapper}></div>
		<div onAnimationEnd={() => setAnimation(false)}
				 className={[style.facescan_flash, animation ? style.start_flash : ''].join(' ')}></div>
		<video className={style.facescan_video} crossOrigin={"anonymous"} ref={videoRef} autoPlay></video>
		{/*<video crossOrigin={"anonymous"} ref={recordedRef} loop style={{zIndex: 2}}></video>*/}
		<canvas className={style.facescan_canvas} ref={canvasRef}/>
		<div className={style.facescan_button_wrapper}>
			{
				isRecording ?
					<Button className={style.next_button} onClick={stopRecording}>Остановить запись</Button>
					:
					<Button className={style.next_button} onClick={startRecording}>Начать запись</Button>
			}
			{/*<Button className={style.next_button} onClick={takePicture}>Сделать фото</Button>*/}
			<Button className={style.back_button} onClick={() => setNextStep(currentPage - 1)}>
				Назад
			</Button>
		</div>
	</>
}
