import style from '../LandingFoqus.module.css'
import {useUnit} from "effector-react";
import {$showPopup, resetShowPopupEv} from "../../../models/newLandingModel";
import {Button, Result} from "antd";
export default function ResponsePopup(){

	const showPopup = useUnit($showPopup)
	// const showPopup = true
	const closePopup = useUnit(resetShowPopupEv)

	return <div className={style.response_popup} style={{display: showPopup ? 'flex' : 'none'}}>
		<div className={style.response_popup_card}>
			<Result
				status="success"
				title={<div className={style.response_popup_text}>Your request is successfully submitted!</div>}
				extra={
					<Button className={style.response_popup_button} onClick={closePopup}>
						Close
					</Button>
				}
			/>
		</div>
	</div>
}
